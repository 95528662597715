import React from 'react';

const MouseIcon = () => {
  return (
    <svg
      width='21'
      height='48'
      viewBox='0 0 21 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_103_108)'>
        <path
          d='M20.9899 15.2103C20.9899 17.5984 21.0181 19.9865 20.983 22.3742C20.9345 25.6473 19.4466 28.0474 16.6409 29.5829C15.6146 30.1445 14.4952 30.4071 13.3298 30.4138C11.4535 30.4244 9.57709 30.428 7.70115 30.4147C3.9891 30.3876 0.733481 27.5204 0.127654 23.7628C0.0488401 23.2734 0.00640184 22.7819 0.00640184 22.2859C0.00770097 17.5837 -0.0104868 12.8816 0.0133305 8.17992C0.0284871 5.04916 1.37395 2.66016 4.00556 1.06084C5.05828 0.42156 6.21364 0.0874996 7.44175 0.0435794C9.361 -0.0251847 11.2807 0.00365181 13.1995 0.0213974C15.279 0.0404739 17.0969 0.787562 18.5969 2.27065C19.948 3.60645 20.7319 5.24392 20.9484 7.16177C21.0194 7.78908 20.986 8.41727 20.9878 9.04458C20.9938 11.1 20.9899 13.1553 20.9899 15.2103ZM18.4956 15.22C18.4956 12.8541 18.4956 10.4886 18.4956 8.12269C18.4956 7.87869 18.4844 7.63647 18.4588 7.39291C18.183 4.75281 15.9411 2.62955 13.3545 2.59939C11.4496 2.5772 9.54418 2.59362 7.63922 2.59584C7.05548 2.59672 6.49252 2.72227 5.95035 2.93611C3.82671 3.77237 2.5016 5.7856 2.5003 8.18303C2.4977 12.8408 2.49943 17.4981 2.5003 22.1559C2.5003 22.533 2.52715 22.9083 2.58128 23.2818C2.95239 25.8368 5.20985 27.8496 7.73059 27.8607C9.57796 27.8686 11.4253 27.8633 13.2727 27.8615C13.6053 27.8615 13.9352 27.8194 14.2592 27.7453C16.7985 27.1659 18.4935 25.0032 18.4956 22.3396C18.4978 19.9665 18.4956 17.5931 18.496 15.22H18.4956Z'
          fill='white'
        />
        <path
          d='M9.22966 43.8632C9.28509 43.7518 9.26431 43.6622 9.26474 43.5761C9.26647 41.2843 9.2669 38.9929 9.26604 36.7011C9.26604 36.1558 9.51374 35.7619 9.98316 35.5339C10.4664 35.2987 10.9294 35.3843 11.3338 35.7379C11.6521 36.0161 11.7608 36.391 11.7604 36.8098C11.7586 39.0422 11.7595 41.275 11.7595 43.5074V43.8432C11.9098 43.7807 11.9994 43.6769 12.0981 43.589C12.7564 43.0016 13.3999 42.3961 14.0745 41.8291C14.8012 41.2186 15.8591 41.6028 16.0661 42.538C16.1713 43.0136 16.0419 43.4351 15.6837 43.7638C15.1576 44.2474 14.6245 44.7234 14.0927 45.2008C13.208 45.9944 12.322 46.7863 11.4373 47.5804C10.8246 48.1301 10.2079 48.1439 9.59472 47.5977C8.1791 46.3369 6.76738 45.0717 5.35912 43.802C4.95596 43.4386 4.79876 42.9786 4.96592 42.4426C5.12528 41.9325 5.48037 41.6299 5.99786 41.5505C6.37677 41.4919 6.71065 41.6197 6.99646 41.8743C7.69842 42.5003 8.39822 43.129 9.09888 43.7563C9.13612 43.7895 9.17596 43.8197 9.22966 43.8636V43.8632Z'
          fill='white'
        />
        <path
          d='M9.26558 10.2856C9.26558 9.67201 9.26299 9.05801 9.26645 8.44446C9.27035 7.72488 9.7835 7.16278 10.4469 7.14459C11.1653 7.12463 11.7404 7.64857 11.7517 8.39078C11.7707 9.65471 11.769 10.9195 11.7517 12.1835C11.743 12.8334 11.2455 13.3524 10.6284 13.4199C10.0251 13.4855 9.4479 13.0676 9.29416 12.4567C9.25736 12.3108 9.26602 12.163 9.26558 12.0158C9.26428 11.439 9.26515 10.8623 9.26515 10.2856H9.26558Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_103_108'>
          <rect width='21' height='48' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MouseIcon;
