const Logo = () => {
  return (
    <svg
      width='220'
      height='60'
      viewBox='0 0 220 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.70445 52.9345H43.23C48.5956 52.9345 52.9345 48.5956 52.9345 43.23V9.70445C52.9345 4.33889 48.5833 0 43.23 0H9.70445C4.33889 0 0 4.33889 0 9.70445V43.23C0 48.5956 4.33889 52.9345 9.70445 52.9345'
        fill='#E30613'
      />
      <path
        d='M95.59 0.0244141V23.2466H90.2122V13.7255H79.6522V23.2466H74.2744V0.0244141H79.6522V9.17886H90.2V0.0244141H95.5777H95.59Z'
        fill='white'
        className='text'
      />
      <path
        d='M117.554 15.7788H104.048C104.268 16.8421 104.879 17.7832 105.771 18.4065C106.774 19.0788 107.959 19.4088 109.157 19.3721C110 19.3843 110.844 19.2499 111.626 18.9565C112.371 18.6632 113.056 18.211 113.63 17.6488L116.38 20.631C114.486 22.6477 111.772 23.711 109.01 23.5154C107.165 23.5521 105.331 23.1488 103.669 22.3421C102.19 21.621 100.944 20.4843 100.088 19.0788C99.2324 17.6488 98.8047 15.9988 98.8291 14.3365C98.8047 12.6743 99.2324 11.0365 100.076 9.60655C100.895 8.21321 102.08 7.07655 103.498 6.31877C106.505 4.76655 110.086 4.7421 113.105 6.26988C114.498 7.00321 115.659 8.12766 116.441 9.50877C117.272 10.9999 117.688 12.6865 117.651 14.3977C117.651 14.4588 117.615 14.9232 117.554 15.791M105.441 10.0465C104.635 10.7432 104.109 11.7088 103.987 12.7721H112.787C112.652 11.721 112.127 10.7554 111.332 10.071C110.514 9.38655 109.475 9.0321 108.411 9.05655C107.336 9.01988 106.297 9.37432 105.454 10.0588'
        fill='white'
        className='text'
      />
      <path
        d='M133.833 7.10106C135.41 8.66551 136.229 10.8411 136.058 13.0533V23.2466H131.218V21.0222C130.24 22.6844 128.431 23.5155 125.779 23.5155C124.557 23.54 123.335 23.3077 122.21 22.8188C121.269 22.4155 120.475 21.7433 119.9 20.8877C119.362 20.0566 119.093 19.0911 119.106 18.1011C119.045 16.5733 119.753 15.1066 120.976 14.19C122.699 13.1144 124.728 12.6133 126.769 12.76H130.888C130.949 11.7822 130.582 10.8166 129.861 10.1444C128.981 9.47218 127.881 9.14218 126.781 9.22773C125.828 9.22773 124.887 9.3744 123.982 9.67995C123.139 9.93662 122.345 10.3522 121.648 10.89L119.79 7.27217C120.853 6.55106 122.039 6.01329 123.286 5.68329C124.655 5.3044 126.06 5.12106 127.478 5.12106C129.776 4.96217 132.049 5.67106 133.846 7.08884M129.433 19.4088C130.118 18.9933 130.631 18.3577 130.888 17.6V15.7788H127.343C125.217 15.7788 124.153 16.4755 124.153 17.8688C124.129 18.4922 124.422 19.0788 124.936 19.4455C125.571 19.8611 126.317 20.0688 127.075 20.0322C127.906 20.0444 128.712 19.8366 129.433 19.4211'
        fill='white'
        className='text'
      />
      <path
        d='M151.311 22.3913C150.749 22.7946 150.113 23.0757 149.441 23.2346C148.671 23.4301 147.889 23.5157 147.106 23.5157C145.31 23.6257 143.55 23.0513 142.181 21.8901C140.946 20.619 140.311 18.8835 140.433 17.1113V9.77792H137.683V5.79348H140.433V1.45459H145.615V5.8057H150.064V9.79015H145.615V17.0624C145.579 17.6979 145.786 18.3213 146.202 18.8102C146.642 19.2379 147.241 19.4579 147.84 19.4213C148.598 19.4457 149.331 19.2135 149.93 18.7613L151.323 22.4157L151.311 22.3913Z'
        fill='white'
        className='text'
      />
      <path
        d='M95.4311 52.8365L95.3944 38.9032L88.5622 50.3798H86.1422L79.3344 39.1965V52.8365H74.2866V29.6021H78.7355L87.4255 44.0365L95.9811 29.6021H100.393L100.454 52.8365H95.4066H95.4311Z'
        fill='white'
        className='text'
      />
      <path
        d='M118.543 36.6909C120.12 38.2553 120.939 40.4309 120.768 42.6431V52.8242H115.928V50.5998C114.95 52.262 113.141 53.0931 110.489 53.0931C109.267 53.1176 108.045 52.8853 106.92 52.3964C105.979 51.9931 105.185 51.3209 104.61 50.4776C104.072 49.6464 103.803 48.6809 103.816 47.6909C103.755 46.1631 104.463 44.6964 105.686 43.7798C107.409 42.7042 109.438 42.2031 111.479 42.3498H115.598C115.659 41.372 115.28 40.4187 114.571 39.7464C113.691 39.0742 112.591 38.7442 111.491 38.8298C110.538 38.8298 109.597 38.9764 108.692 39.282C107.849 39.5387 107.055 39.9542 106.358 40.492L104.5 36.8742C105.563 36.1531 106.749 35.6153 107.996 35.2853C109.365 34.9064 110.77 34.7231 112.188 34.7231C114.486 34.5642 116.747 35.2731 118.556 36.6909M114.143 48.9987C114.828 48.5831 115.341 47.9476 115.598 47.1898V45.3687H112.053C109.927 45.3687 108.863 46.0653 108.863 47.4587C108.839 48.082 109.132 48.6687 109.646 49.0353C110.281 49.4509 111.027 49.6587 111.785 49.6098C112.616 49.622 113.422 49.4142 114.143 48.9987Z'
        fill='white'
        className='text'
      />
      <path
        d='M140.8 36.7032C142.267 38.3043 143 40.4432 142.841 42.6065V52.8243H137.659V43.4009C137.744 42.2643 137.414 41.1398 136.73 40.2354C136.033 39.5021 135.043 39.1232 134.041 39.1843C132.88 39.1232 131.743 39.5632 130.924 40.3943C130.081 41.3965 129.665 42.6921 129.763 43.9998V52.8243H124.593V34.9676H129.543V37.0576C130.252 36.2876 131.12 35.6887 132.098 35.3098C133.161 34.8943 134.298 34.6865 135.447 34.6987C137.427 34.6132 139.358 35.3343 140.812 36.6909'
        fill='white'
        className='text'
      />
      <path
        d='M160.209 36.6909C161.786 38.2553 162.605 40.4309 162.433 42.6431V52.8242H157.593V50.5998C156.616 52.262 154.807 53.0931 152.155 53.0931C150.932 53.1176 149.71 52.8853 148.586 52.3964C147.645 51.9931 146.85 51.3209 146.276 50.4776C145.738 49.6464 145.469 48.6809 145.481 47.6909C145.42 46.1631 146.129 44.6964 147.351 43.7798C149.075 42.7042 151.103 42.2031 153.145 42.3498H157.263C157.325 41.372 156.946 40.4187 156.237 39.7464C155.357 39.0742 154.257 38.7442 153.157 38.8298C152.203 38.8298 151.262 38.9764 150.346 39.282C149.502 39.5387 148.708 39.9542 148.011 40.492L146.153 36.8742C147.217 36.1531 148.402 35.6153 149.649 35.2853C151.018 34.9064 152.423 34.7231 153.841 34.7231C156.139 34.5642 158.412 35.2731 160.209 36.6909ZM155.797 48.9987C156.481 48.5831 157.007 47.9476 157.263 47.1898V45.3687H153.719C151.592 45.3687 150.529 46.0653 150.529 47.4587C150.505 48.082 150.798 48.6687 151.311 49.0353C151.947 49.4509 152.692 49.6587 153.45 49.6098C154.281 49.622 155.088 49.4142 155.809 48.9987'
        fill='white'
        className='text'
      />
      <path
        d='M184.665 34.9799V49.8421C184.665 53.1177 183.81 55.5499 182.111 57.1388C180.412 58.7277 177.919 59.5344 174.643 59.5344C172.981 59.5344 171.331 59.3266 169.73 58.8988C168.337 58.5566 167.029 57.9332 165.88 57.0777L167.933 53.3621C168.777 54.0344 169.754 54.5355 170.793 54.841C171.905 55.1955 173.079 55.391 174.24 55.391C175.67 55.5132 177.088 55.0732 178.2 54.181C179.117 53.191 179.581 51.8588 179.471 50.5144V49.7566C178.004 51.2844 175.939 52.1032 173.824 51.981C172.26 51.9932 170.72 51.6266 169.327 50.9055C167.994 50.2088 166.87 49.1577 166.088 47.8743C164.499 45.0755 164.499 41.641 166.088 38.8421C166.87 37.5588 167.982 36.5077 169.327 35.811C170.708 35.0899 172.26 34.711 173.824 34.7355C176.085 34.5888 178.285 35.5421 179.728 37.2899V35.0043H184.641L184.665 34.9799ZM178.249 46.5177C179.997 44.7699 179.997 41.9343 178.249 40.1743C177.32 39.3432 176.122 38.9155 174.875 38.9643C173.629 38.9155 172.419 39.3555 171.49 40.1743C169.742 41.8977 169.718 44.7088 171.441 46.4566C171.453 46.4688 171.478 46.4932 171.49 46.5055C172.419 47.3244 173.629 47.7644 174.875 47.7155C176.11 47.7644 177.32 47.3244 178.249 46.5055'
        fill='white'
        className='text'
      />
      <path
        d='M206.14 45.3691H192.634C192.842 46.4324 193.466 47.3735 194.358 47.9969C195.36 48.6691 196.546 48.9991 197.743 48.9624C198.587 48.9747 199.43 48.8402 200.212 48.5469C200.958 48.2535 201.642 47.8013 202.217 47.2391L204.967 50.2213C203.072 52.238 200.359 53.3013 197.597 53.1058C195.751 53.1424 193.918 52.7391 192.256 51.9324C190.777 51.2113 189.53 50.0747 188.674 48.6691C187.819 47.2391 187.391 45.5891 187.416 43.9269C187.391 42.2647 187.819 40.6269 188.662 39.1969C189.481 37.8035 190.667 36.6669 192.084 35.9091C195.091 34.3569 198.66 34.3324 201.691 35.8602C203.084 36.5935 204.246 37.718 205.028 39.0991C205.859 40.5902 206.274 42.2769 206.238 43.988C206.238 44.0491 206.201 44.5135 206.14 45.3813M194.028 39.6369C193.221 40.3335 192.696 41.2991 192.573 42.3624H201.373C201.239 41.3113 200.713 40.3458 199.907 39.6613C199.088 38.9769 198.049 38.6224 196.986 38.6469C195.91 38.6102 194.871 38.9647 194.028 39.6491'
        fill='white'
        className='text'
      />
      <path
        d='M216.37 35.3713C217.519 34.9069 218.766 34.6869 220 34.7113V39.4902C219.426 39.4413 219.035 39.4291 218.839 39.4291C217.556 39.3557 216.297 39.808 215.356 40.6757C214.439 41.7024 213.987 43.0469 214.097 44.4158V52.8491H208.915V34.9924H213.852V37.3513C214.488 36.4713 215.356 35.7991 216.358 35.3957'
        fill='white'
        className='text'
      />
      <path
        d='M39.6979 22.5742H13.2368V30.3598H39.6979V22.5742Z'
        fill='white'
      />
      <path
        d='M6.17211 34.5156H0.879883V42.3012H6.17211V34.5156Z'
        fill='white'
      />
      <path d='M18.8954 0H11.1099V29.5778H18.8954V0Z' fill='white' />
      <path d='M41.8246 0H34.0391V29.5778H41.8246V0Z' fill='white' />
      <path
        d='M41.8246 23.3564H34.0391V52.9342H41.8246V23.3564Z'
        fill='white'
      />
      <path
        d='M6.17236 42.2887V34.5032C8.89792 34.5032 11.0979 32.291 11.0979 29.5776H18.8835C18.8835 36.5932 13.1879 42.2765 6.17236 42.2887'
        fill='white'
      />
      <path d='M20.7291 0H9.26465V1.76H20.7291V0Z' fill='white' />
      <path d='M43.67 0H32.2056V1.76H43.67V0Z' fill='white' />
      <path d='M43.67 51.1621H32.2056V52.9221H43.67V51.1621Z' fill='white' />
      <path d='M1.76 32.6699H0V44.1344H1.76V32.6699Z' fill='white' />
      <path
        d='M7.05199 32.6699H5.29199V44.1344H7.05199V32.6699Z'
        fill='white'
      />
      <path d='M27.3411 20.729H25.5811V32.1935H27.3411V20.729Z' fill='white' />
      <path
        d='M47.4958 25.5811H43.4502V27.3411H47.4958V25.5811Z'
        fill='white'
      />
      <path d='M48.7668 20.729H47.0068V32.1935H48.7668V20.729Z' fill='white' />
      <path
        d='M44.5501 26.4613C44.5501 30.1158 41.5924 33.0736 37.9379 33.0736C34.2835 33.0736 31.3257 30.1158 31.3257 26.4613C31.3257 22.8069 34.2835 19.8491 37.9379 19.8491C41.5924 19.8491 44.5501 22.8069 44.5501 26.4613Z'
        fill='white'
      />
      <path
        d='M41.0179 26.4609C41.0179 28.172 39.6368 29.5531 37.9257 29.5531C36.2146 29.5531 34.8335 28.172 34.8335 26.4609C34.8335 24.7498 36.2146 23.3687 37.9257 23.3687C39.6368 23.3687 41.0179 24.7498 41.0179 26.4609Z'
        fill='#5A89FF'
      />
    </svg>
  );
};

export default Logo;
