import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Container from '../../components/Container/Container';

import BadgeIcon from '../../icons/BadgeIcon';
import BuildingsIcon from '../../icons/BuildingsIcon';
import CalculatorIcon from '../../icons/CalculatorIcon';
import CupIcon from '../../icons/CupIcon';
import DotsIcon from '../../icons/DotsIcon';
import IncreaseIcon from '../../icons/IncreaseIcon';
import LifebeltIcon from '../../icons/LifebeltIcon';
import MoneyboxIcon from '../../icons/MoneyboxIcon';
import MoneyboxThickIcon from '../../icons/MoneyboxThickIcon';
import NodeIcon from '../../icons/NodeIcon';

import './Home.scss';

const Home = () => {
  const { t } = useTranslation('home');

  const [isActiveButtonFirst, setIsActiveButtonFirst] = useState(0);
  const [isActiveButtonSecond, setIsActiveButtonSecond] = useState(0);

  useEffect(() => {
    document.title = `Heat Manager`;
  }, [t]);

  return (
    <div id='home'>
      <Container>
        <h2>{t('WHAT_IS_HEAT_MANAGER')}</h2>
        <DotsIcon />
        <img
          src={require('../../images/home/screens.webp')}
          alt='Screens from application'
        />
        <p>
          <Trans
            i18nKey='home:HEAT_MANAGER_DESCRIPTION'
            components={{
              br: <br />,
            }}
          />
        </p>
        <div className='actions'>
          <Link
            to='/system-features'
            className={`link-button ${
              isActiveButtonFirst === 0 || isActiveButtonFirst === 1
                ? ''
                : 'white'
            }`}
            onMouseEnter={() => setIsActiveButtonFirst(1)}
            onMouseLeave={() => setIsActiveButtonFirst(0)}
          >
            {t('MORE_ABOUT_SYSTEM')}
          </Link>
          <Link
            to='/devices'
            className={`link-button ${
              isActiveButtonFirst !== 2 ? 'white' : ''
            }`}
            onMouseEnter={() => setIsActiveButtonFirst(2)}
            onMouseLeave={() => setIsActiveButtonFirst(0)}
          >
            {t('COMMUNICATION_DEVICES')}
          </Link>
          <Link
            to='/application'
            className={`link-button ${
              isActiveButtonFirst !== 3 ? 'white' : ''
            }`}
            onMouseEnter={() => setIsActiveButtonFirst(3)}
            onMouseLeave={() => setIsActiveButtonFirst(0)}
          >
            {t('APPLICATION_FEATURES')}
          </Link>
        </div>
      </Container>
      <Container>
        <div>
          <div className='text-container'>
            <h2>{t('WHO_WE_ARE')}</h2>
            <DotsIcon />
            <p>{t('WHO_WE_ARE_DESCRIPTION')}</p>
          </div>
          <div className='icons-container'>
            <div>
              <span>
                <CupIcon />
              </span>
              <p>{t('ACHIEVEMENT_0')}</p>
            </div>
            <div>
              <span>
                <NodeIcon />
              </span>
              <p>{t('ACHIEVEMENT_1')}</p>
            </div>
            <div>
              <span>
                <IncreaseIcon />
              </span>
              <p>{t('ACHIEVEMENT_2')}</p>
            </div>
            <div>
              <span>
                <BuildingsIcon />
              </span>
              <p>{t('ACHIEVEMENT_3')}</p>
            </div>
            <div>
              <span>
                <MoneyboxThickIcon />
              </span>
              <p>{t('ACHIEVEMENT_4')}</p>
            </div>
          </div>
        </div>
      </Container>
      <Container>
        <div className='text-container'>
          <div>
            <h2>{t('WHAT_IS_SMART_GRID')}</h2>
            <DotsIcon />
            <p>{t('SMART_GRID_DESCRIPTION')}</p>
          </div>
          <div>
            <h2>{t('WHAT_IS_SMART_METERING')}</h2>
            <DotsIcon />
            <p>{t('SMART_METERING_DESCRIPTION')}</p>
          </div>
        </div>
        <div className='icons-container'>
          <div>
            <MoneyboxIcon />
            <h5>{t('FEATURE_1')}</h5>
            <p>{t('FEATURE_1_DESCRIPTION')}</p>
          </div>
          <div>
            <CalculatorIcon />
            <h5>{t('FEATURE_2')}</h5>
            <p>{t('FEATURE_2_DESCRIPTION')}</p>
          </div>
          <div>
            <LifebeltIcon />
            <h5>{t('FEATURE_3')}</h5>
            <p>{t('FEATURE_3_DESCRIPTION')}</p>
          </div>
          <div>
            <BadgeIcon />
            <h5>{t('FEATURE_4')}</h5>
            <p>{t('FEATURE_4_DESCRIPTION')}</p>
          </div>
        </div>
        <div className='actions'>
          <Link
            to='/system-features'
            className={`link-button ${
              isActiveButtonSecond === 0 || isActiveButtonSecond === 1
                ? ''
                : 'white'
            }`}
            onMouseEnter={() => setIsActiveButtonSecond(1)}
            onMouseLeave={() => setIsActiveButtonSecond(0)}
          >
            {t('MORE_ABOUT_SYSTEM')}
          </Link>
          <Link
            to='/application'
            className={`link-button ${
              isActiveButtonSecond !== 2 ? 'white' : ''
            }`}
            onMouseEnter={() => setIsActiveButtonSecond(2)}
            onMouseLeave={() => setIsActiveButtonSecond(0)}
          >
            {t('HEAT_MANAGER_APPLICATION')}
          </Link>
          <Link
            to='/devices'
            className={`link-button ${
              isActiveButtonSecond !== 3 ? 'white' : ''
            }`}
            onMouseEnter={() => setIsActiveButtonSecond(3)}
            onMouseLeave={() => setIsActiveButtonSecond(0)}
          >
            {t('COMMUNICATION_DEVICES')}
          </Link>
        </div>
        <div>
          <h5>
            <Trans
              i18nKey='home:FEATURE_5_DESCRIPTION'
              components={{
                b: <strong></strong>,
              }}
            />
          </h5>
        </div>
      </Container>
      <Container className='white'>
        <div className='text-container'>
          <h2>{t('NODA')}</h2>
          <DotsIcon />
          <p>{t('NODA_DESCRIPTION')}</p>
          <p>{t('NODA_DESCRIPTION_2')}</p>
        </div>
        <div className='image-container'>
          <img src={require('../../images/home/noda.webp')} alt='NODA logo' />
        </div>
        <div>
          <Link to='/system-features#noda' className='link-button'>
            {t('MORE_ABOUT_ADVANTAGES')}
          </Link>
        </div>
      </Container>
      <Container>
        <div className='text-container'>
          <h2>{t('WORKING_WITH_US')}</h2>
          <DotsIcon />
          <p>{t('WORKING_WITH_US_DESCRIPTION')}</p>
          <div>
            <img
              src={require('../../images/home/mpec-logo.webp')}
              alt='MPEC logo'
            />
          </div>
          <p>
            <Trans
              i18nKey='home:MPEC'
              components={{
                a: (
                  <a
                    href='https://mpec.olsztyn.pl/telemetria/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    MPEC
                  </a>
                ),
              }}
            />
          </p>
        </div>
        <div>
          <img
            src={require('../../images/home/engineer.webp')}
            alt='Engineer'
          />
        </div>
      </Container>
    </div>
  );
};

export default Home;
