const NodeIcon = () => {
  return (
    <svg
      width='50'
      height='50'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.88477 11.5601L12.0098 18.6417M21.3548 27.9901L28.4398 30.1151'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.4717 20.5283L29.07 10.9299'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.16699 11.6667C10.5477 11.6667 11.667 10.5475 11.667 9.16675C11.667 7.78604 10.5477 6.66675 9.16699 6.66675C7.78628 6.66675 6.66699 7.78604 6.66699 9.16675C6.66699 10.5475 7.78628 11.6667 9.16699 11.6667Z'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M30.833 11.6667C32.2137 11.6667 33.333 10.5475 33.333 9.16675C33.333 7.78604 32.2137 6.66675 30.833 6.66675C29.4523 6.66675 28.333 7.78604 28.333 9.16675C28.333 10.5475 29.4523 11.6667 30.833 11.6667Z'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M30.833 33.3333C32.2137 33.3333 33.333 32.214 33.333 30.8333C33.333 29.4525 32.2137 28.3333 30.833 28.3333C29.4523 28.3333 28.333 29.4525 28.333 30.8333C28.333 32.214 29.4523 33.3333 30.833 33.3333Z'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.167 33.3333C18.3091 33.3333 21.667 29.9754 21.667 25.8333C21.667 21.6911 18.3091 18.3333 14.167 18.3333C10.0249 18.3333 6.66699 21.6911 6.66699 25.8333C6.66699 29.9754 10.0249 33.3333 14.167 33.3333Z'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default NodeIcon;
