const IncreaseIcon = () => {
  return (
    <svg viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5 8.33341C5 7.89139 5.17559 7.46746 5.48816 7.1549C5.80072 6.84234 6.22464 6.66675 6.66667 6.66675H33.3333C33.7754 6.66675 34.1993 6.84234 34.5118 7.1549C34.8244 7.46746 35 7.89139 35 8.33341V25.0001C35 25.4421 34.8244 25.866 34.5118 26.1786C34.1993 26.4912 33.7754 26.6667 33.3333 26.6667H6.66667C6.22464 26.6667 5.80072 26.4912 5.48816 26.1786C5.17559 25.866 5 25.4421 5 25.0001V8.33341Z'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.667 33.3333H28.3337'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15 26.6667V33.3334'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M25 26.6667V33.3334'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.333 19.9999L18.333 14.9999L21.6663 18.3333L26.6663 13.3333'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IncreaseIcon;
