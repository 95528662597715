const MoneyboxIcon = () => {
  return (
    <svg
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M37.5 27.5002V27.5252'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.933 20.9451C11.8365 20.1024 10.9944 18.9729 10.4997 17.6814C10.005 16.39 9.87699 14.9869 10.1298 13.6273C10.3827 12.2677 11.0065 11.0044 11.9324 9.97717C12.8583 8.94993 14.0502 8.19868 15.3764 7.80648C16.7025 7.41427 18.1113 7.39637 19.447 7.75475C20.7827 8.11314 21.9933 8.83385 22.945 9.83724C23.8967 10.8406 24.5525 12.0876 24.8398 13.4404C25.1271 14.7932 25.0348 16.199 24.573 17.5026'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M40.0005 9.99976V19.5073C43.0945 21.2976 45.4501 24.1309 46.6455 27.4998H49.998C50.661 27.4998 51.2969 27.7631 51.7657 28.232C52.2346 28.7008 52.498 29.3367 52.498 29.9998V34.9998C52.498 35.6628 52.2346 36.2987 51.7657 36.7675C51.2969 37.2364 50.661 37.4998 49.998 37.4998H46.643C45.803 39.8748 44.3755 41.9998 42.498 43.6823V48.7498C42.498 49.7443 42.1029 50.6981 41.3996 51.4014C40.6964 52.1047 39.7425 52.4998 38.748 52.4998C37.7534 52.4998 36.7996 52.1047 36.0963 51.4014C35.3931 50.6981 34.998 49.7443 34.998 48.7498V47.2923C34.1718 47.4306 33.3356 47.5001 32.498 47.4998H22.498C21.6603 47.5001 20.8241 47.4306 19.998 47.2923V48.7498C19.998 49.7443 19.6029 50.6981 18.8996 51.4014C18.1964 52.1047 17.2425 52.4998 16.248 52.4998C15.2534 52.4998 14.2996 52.1047 13.5963 51.4014C12.8931 50.6981 12.498 49.7443 12.498 48.7498V43.7498L12.5005 43.6823C10.2355 41.6573 8.63888 38.9923 7.92182 36.04C7.20476 33.0877 7.40111 29.9872 8.48487 27.149C9.56863 24.3107 11.4887 21.8684 13.991 20.1454C16.4933 18.4224 19.4598 17.4998 22.498 17.4998H28.748L39.998 9.99976H40.0005V9.99976Z'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default MoneyboxIcon;
