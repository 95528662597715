import { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';
import Loader from '../Loader/Loader';
import Button from '../Button/Button';
import HomeHeader from '../Header/HomeHeader';

import './Layout.scss';

const Header = lazy(() => import('../Header/Header'));
const Footer = lazy(() => import('../Footer/Footer'));

const Layout = () => {
  let { pathname } = useLocation();
  const ref = useRef();

  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div ref={ref}>
      <ScrollRestoration />
      {pathname === '/' ? (
        <HomeHeader />
      ) : (
        <Suspense fallback={<Loader />}>
          <Header pathname={pathname} />
        </Suspense>
      )}
      <main>
        <Outlet />
      </main>
      <Suspense fallback={<Loader />}>
        <Footer />
      </Suspense>
      {scrollTop > 800 && (
        <Button
          onClick={() =>
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
          }
          className='scroll-to-top'
        >
          {' '}
        </Button>
      )}
    </div>
  );
};

export default Layout;
