const LifebeltIcon = () => {
  return (
    <svg
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M30 40.0002C35.5228 40.0002 40 35.5231 40 30.0002C40 24.4774 35.5228 20.0002 30 20.0002C24.4772 20.0002 20 24.4774 20 30.0002C20 35.5231 24.4772 40.0002 30 40.0002Z'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M30 52.5C42.4264 52.5 52.5 42.4264 52.5 30C52.5 17.5736 42.4264 7.5 30 7.5C17.5736 7.5 7.5 17.5736 7.5 30C7.5 42.4264 17.5736 52.5 30 52.5Z'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M37.5 37.5L45.875 45.875'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.5 37.5L14.125 45.875'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.125 14.1248L22.5 22.4998'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M45.875 14.1248L37.5 22.4998'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default LifebeltIcon;
