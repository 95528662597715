const CupIcon = () => {
  return (
    <svg
      width='50'
      height='50'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.333 35H26.6663'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20 28.3333V34.9999'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.667 6.66675H28.3337'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M28.3337 6.66675V20.0001C28.3337 22.2102 27.4557 24.3298 25.8929 25.8926C24.3301 27.4554 22.2105 28.3334 20.0003 28.3334C17.7902 28.3334 15.6706 27.4554 14.1078 25.8926C12.545 24.3298 11.667 22.2102 11.667 20.0001V6.66675'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.33333 18.3334C10.1743 18.3334 11.6667 16.841 11.6667 15.0001C11.6667 13.1591 10.1743 11.6667 8.33333 11.6667C6.49238 11.6667 5 13.1591 5 15.0001C5 16.841 6.49238 18.3334 8.33333 18.3334Z'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M31.6663 18.3334C33.5073 18.3334 34.9997 16.841 34.9997 15.0001C34.9997 13.1591 33.5073 11.6667 31.6663 11.6667C29.8254 11.6667 28.333 13.1591 28.333 15.0001C28.333 16.841 29.8254 18.3334 31.6663 18.3334Z'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default CupIcon;
