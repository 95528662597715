const MoneyboxThickIcon = () => {
  return (
    <svg viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M25 18.3335V18.3502'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.62203 13.9634C7.89103 13.4016 7.3296 12.6486 6.9998 11.7876C6.67 10.9267 6.58466 9.99129 6.75323 9.08487C6.92179 8.17845 7.33769 7.33628 7.95495 6.65145C8.57222 5.96662 9.36682 5.46579 10.2509 5.20432C11.135 4.94284 12.0742 4.93091 12.9647 5.16983C13.8551 5.40876 14.6622 5.88924 15.2967 6.55816C15.9312 7.22709 16.3683 8.05841 16.5599 8.96026C16.7514 9.8621 16.6899 10.7993 16.382 11.6684'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M26.6673 6.6665V13.0048C28.73 14.1984 30.3004 16.0872 31.0973 18.3332H33.3323C33.7743 18.3332 34.1983 18.5088 34.5108 18.8213C34.8234 19.1339 34.999 19.5578 34.999 19.9998V23.3332C34.999 23.7752 34.8234 24.1991 34.5108 24.5117C34.1983 24.8242 33.7743 24.9998 33.3323 24.9998H31.0956C30.5356 26.5832 29.584 27.9998 28.3323 29.1215V32.4998C28.3323 33.1629 28.0689 33.7988 27.6001 34.2676C27.1312 34.7364 26.4953 34.9998 25.8323 34.9998C25.1693 34.9998 24.5334 34.7364 24.0645 34.2676C23.5957 33.7988 23.3323 33.1629 23.3323 32.4998V31.5282C22.7815 31.6204 22.2241 31.6667 21.6656 31.6665H14.999C14.4405 31.6667 13.8831 31.6204 13.3323 31.5282V32.4998C13.3323 33.1629 13.0689 33.7988 12.6001 34.2676C12.1312 34.7364 11.4953 34.9998 10.8323 34.9998C10.1693 34.9998 9.53338 34.7364 9.06454 34.2676C8.59569 33.7988 8.3323 33.1629 8.3323 32.4998V29.1665L8.33397 29.1215C6.82401 27.7715 5.75958 25.9949 5.28154 24.0267C4.8035 22.0585 4.9344 19.9915 5.65691 18.0993C6.37942 16.2071 7.65947 14.579 9.32766 13.4303C10.9959 12.2816 12.9735 11.6665 14.999 11.6665H19.1656L26.6656 6.6665H26.6673V6.6665Z'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default MoneyboxThickIcon;
