import { node, string } from 'prop-types';

const Container = ({ children, className }) => {
  return (
    <section className={className}>
      <div className='container'>{children}</div>
    </section>
  );
};

Container.propTypes = {
  children: node.isRequired,
  className: string,
};

export default Container;
