const DotsIcon = () => {
  return (
    <svg
      width='55'
      height='5'
      viewBox='0 0 55 5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='2.5' cy='2.5' r='2.5' fill='#E20A17' />
      <circle cx='27.5' cy='2.5' r='2.5' fill='#E20A17' />
      <circle cx='52.5' cy='2.5' r='2.5' fill='#E20A17' />
    </svg>
  );
};

export default DotsIcon;
