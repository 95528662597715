import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import Loader from './components/Loader/Loader';
import Layout from './components/Layout/Layout';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import Home from './pages/Home/Home';
import ReactGA from 'react-ga4';

import './App.scss';
import './components/Header/Header.scss';

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY);
}

const AboutUs = lazy(() => import('./pages/AboutUs/AboutUs'));
const Application = lazy(() => import('./pages/Application/Application'));
const Contact = lazy(() => import('./pages/Contact/Contact'));
const Devices = lazy(() => import('./pages/Devices/Devices'));
const Downloads = lazy(() => import('./pages/Downloads/Downloads'));
const SystemFeatures = lazy(() =>
  import('./pages/SystemFeatures/SystemFeatures')
);

const router = createBrowserRouter([
  {
    element: <Layout />,
    path: '/',
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      {
        path: 'about-us',
        element: (
          <Suspense fallback={<Loader />}>
            <AboutUs />
          </Suspense>
        ),
      },
      {
        path: 'application',
        element: (
          <Suspense fallback={<Loader />}>
            <Application />
          </Suspense>
        ),
      },
      {
        path: 'contact',
        element: (
          <Suspense fallback={<Loader />}>
            <Contact />
          </Suspense>
        ),
      },
      {
        path: 'devices',
        element: (
          <Suspense fallback={<Loader />}>
            <Devices />
          </Suspense>
        ),
      },
      {
        path: 'system-features',
        element: (
          <Suspense fallback={<Loader />}>
            <SystemFeatures />
          </Suspense>
        ),
      },
      {
        path: 'downloads',
        element: (
          <Suspense fallback={<Loader />}>
            <Downloads />
          </Suspense>
        ),
      },
    ],
  },
]);

function App() {
  if (process.env.NODE_ENV !== 'development') {
    console.log = () => {};
    console.debug = () => {};
  }

  return (
    <>
      <ToastContainer
        autoClose={3000}
        closeOnClick
        pauseOnHover
        position='top-right'
      />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
