const EnvelopeIcon = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M31.6667 8.3335H8.33333C6.49238 8.3335 5 9.82588 5 11.6668V28.3335C5 30.1744 6.49238 31.6668 8.33333 31.6668H31.6667C33.5076 31.6668 35 30.1744 35 28.3335V11.6668C35 9.82588 33.5076 8.3335 31.6667 8.3335Z'
        stroke='currentColor'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5 11.6665L20 21.6665L35 11.6665'
        stroke='currentColor'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default EnvelopeIcon;
