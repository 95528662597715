const BadgeIcon = () => {
  return (
    <svg
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M30 37.5C38.2843 37.5 45 30.7843 45 22.5C45 14.2157 38.2843 7.5 30 7.5C21.7157 7.5 15 14.2157 15 22.5C15 30.7843 21.7157 37.5 30 37.5Z'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M30.0049 37.5073L38.5049 52.2323L42.4999 44.1498L51.4949 44.7298L42.9949 30.0073'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.0049 30.0073L8.50488 44.7323L17.4999 44.1498L21.4949 52.2298L29.9949 37.5073'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default BadgeIcon;
