import { any, bool, func, node, string } from 'prop-types';

import './Button.scss';

const Button = ({
  children,
  colored,
  onClick,
  disabled,
  className,
  onSubmit,
  type,
}) => {
  const classes = `button ${colored ? 'red' : ''} ${className}`;
  return (
    <button
      className={classes}
      onClick={onClick}
      onSubmit={onSubmit}
      disabled={disabled}
      type={type}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: node.isRequired,
  colored: any,
  onClick: func,
  disabled: bool,
  className: string,
  onSubmit: func,
  type: string,
};

export default Button;
