import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Nav from '../Nav/Nav';
import MouseIcon from '../../icons/MouseIcon';

import './Header.scss';

const HomeHeader = () => {
  const { t } = useTranslation('home');

  const [isActive, setIsActive] = useState(0);

  const [isImgLoaded, setIsImgLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => setIsImgLoaded(true);
    img.src = require('../../images/home-header-bg.png');
  }, []);

  return (
    <header className={`home-header ${isImgLoaded && 'with-bg-img'}`}>
      <Nav />
      <div className='container'>
        <div>
          <h1>{t('HEADER_TITLE')}</h1>
          <p>{t('HEADER_DESCRIPTION')}</p>
        </div>
        <div className='actions'>
          <Link
            to='system-features'
            className={`link-button ${isActive === 2 ? 'white' : ''}`}
            onMouseEnter={() => setIsActive(1)}
            onMouseLeave={() => setIsActive(0)}
          >
            {t('ABOUT_SYSTEM')}
          </Link>
          <Link
            to='contact'
            className={`link-button ${
              isActive === 0 || isActive === 1 ? 'white' : ''
            }`}
            onMouseEnter={() => setIsActive(2)}
            onMouseLeave={() => setIsActive(0)}
          >
            {t('CONTACT')}
          </Link>
        </div>
      </div>
      <span
        onClick={() => {
          document.getElementById('home').scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }}
      >
        <MouseIcon />
      </span>
    </header>
  );
};

export default HomeHeader;
