import { useEffect } from 'react';
import { Navigate, useRouteError } from 'react-router-dom';

const ErrorPage = () => {
  const error = useRouteError();

  useEffect(() => {
    if (error && error.error && error.error.message) {
      console.error(error.error.message);
    }
  }, [error]);

  return <Navigate to='/' />;
};

export default ErrorPage;
