const CalculatorIcon = () => {
  return (
    <svg
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M45.0005 7.5H15.0005C12.2391 7.5 10.0005 9.73858 10.0005 12.5V47.5C10.0005 50.2614 12.2391 52.5 15.0005 52.5H45.0005C47.7619 52.5 50.0005 50.2614 50.0005 47.5V12.5C50.0005 9.73858 47.7619 7.5 45.0005 7.5Z'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M37.4995 17.5H22.4995C21.1188 17.5 19.9995 18.6193 19.9995 20V22.5C19.9995 23.8807 21.1188 25 22.4995 25H37.4995C38.8802 25 39.9995 23.8807 39.9995 22.5V20C39.9995 18.6193 38.8802 17.5 37.4995 17.5Z'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.9995 35V35.025'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M30 35V35.025'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M40.0005 35V35.025'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.9995 42.5V42.525'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M30 42.5V42.525'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M40.0005 42.5V42.525'
        stroke='#E20A17'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default CalculatorIcon;
