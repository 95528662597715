const PhoneIcon = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M26.6667 5H13.3333C11.4924 5 10 6.49238 10 8.33333V31.6667C10 33.5076 11.4924 35 13.3333 35H26.6667C28.5076 35 30 33.5076 30 31.6667V8.33333C30 6.49238 28.5076 5 26.6667 5Z'
        stroke='currentColor'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.334 6.6665H21.6673'
        stroke='currentColor'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20 28.3335V28.3502'
        stroke='currentColor'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default PhoneIcon;
