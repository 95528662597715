import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, NavLink, useLocation} from 'react-router-dom';

import LanguageSelector from "../LanguageSelector/LanguageSelector";
import EnvelopeIcon from '../../icons/EnvelopeIcon';
import PhoneIcon from '../../icons/PhoneIcon';
import Logo from '../../images/Logo';

import './Nav.scss';

const Nav = () => {
    const {t} = useTranslation();
    const location = useLocation();

    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        setShowMenu(false);
    }, [location]);

    return (
        <nav id='nav'>
            <div className='container'>
                <div className='logo-container'>
                    <Link to='/' aria-label='Homepage'>
                        <Logo/>
                    </Link>
                </div>
                <button
                    className={`hamburger-menu ${showMenu ? 'active' : ''}`}
                    onClick={() => setShowMenu((prev) => !prev)}
                >
                    <span className='line line1'></span>
                    <span className='line line2'></span>
                    <span className='line line3'></span>
                </button>
                <div className='links'>
                    <ul>
                        <li>
                            <PhoneIcon/>
                            <a href='tel:+48898881016'>+48 89 888 10 16</a>
                        </li>
                        <li>
                            <EnvelopeIcon/>
                            <a href='mailto:heatmanager@treesat.io'>heatmanager@treesat.io</a>
                        </li>
                    </ul>
                    <ul className={`menu-items ${showMenu ? 'active' : ''}`}>
                        <li>
                            <LanguageSelector/>
                        </li>
                        <li>
                            <NavLink
                                to='system-features'
                                className={({isActive}) => (isActive ? 'active' : undefined)}
                            >
                                {t('SYSTEM_FEATURES')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to='application'
                                className={({isActive}) => (isActive ? 'active' : undefined)}
                            >
                                {t('APPLICATION')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to='devices'
                                className={({isActive}) => (isActive ? 'active' : undefined)}
                            >
                                {t('DEVICES')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to='about-us'
                                className={({isActive}) => (isActive ? 'active' : undefined)}
                            >
                                {t('ABOUT_US')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to='contact'
                                className={({isActive}) => (isActive ? 'active' : undefined)}
                            >
                                {t('CONTACT')}
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Nav;
