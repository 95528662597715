import {useTranslation} from "react-i18next";

import "./LanguageSelector.scss";

const LanguageSelector = () => {
    const {i18n} = useTranslation();
    const currentLanguage = i18n.language;

    return <div className="language-selector">
        <span className={currentLanguage.includes('pl') ? 'active' : null} onClick={() => i18n.changeLanguage('pl')}>
            <img src={require('../../images/locales/pl.png')} alt="Polski" title="Polski"/>
        </span>
        <span className={currentLanguage.includes('en') ? 'active' : null} onClick={() => i18n.changeLanguage('en')}>
            <img src={require('../../images/locales/en.png')} alt="English" title="English"/>
        </span>
    </div>
}

export default LanguageSelector;
